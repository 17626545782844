import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

import Img1 from './img/event-1.jpg';
import Img2 from './img/event-2.jpg';
import Img3 from './img/event-3.jpg';
import Img4 from './img/event-4.jpg';
import Img5 from './img/event-5.png';
import Img6 from './img/event-6.jpg';
import Img7 from './img/event-7.jpg';
import Img8 from './img/event-8.jpg';
import Img9 from './img/event-9.jpg';
import Img10 from './img/event-10.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { HashLink } from 'react-router-hash-link';
const Gallery = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  let data = [
    { id: 1, imgSrc: Img1 },
    { id: 2, imgSrc: Img2 },
    { id: 3, imgSrc: Img3 },
    { id: 4, imgSrc: Img4 },
    { id: 5, imgSrc: Img5 },
    { id: 6, imgSrc: Img6 },
    { id: 7, imgSrc: Img7 },
    { id: 8, imgSrc: Img8 },
    { id: 9, imgSrc: Img9 },
    { id: 10, imgSrc: Img10 }
  ]
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <>
      {/* <section className='home-gallery'> */}
      <section className='home-gallery' >
        <div className={model ? "model open" : "model"}>
          <img src={tempimgSrc} />
          <i className='fa fa-times' onClick={() => setModel(false)}></i>
        </div>
        <div className='container'>
          <div className='row m-0'>
           
           
            <div className='col-9'><h3 className="fw-bold">EVENTFUL MEMORIES</h3></div>
            <div className='col-3 text-right'>
              {/* <NavLink className="text-blue" to="/videos">View All</NavLink> */}
              <HashLink smooth to='/memorygallery' target='_blank'> View All  <i className='fa fa-long-arrow-right'></i></HashLink>
           
          </div>
            {/* <div className='col-6 text-right'>
            <NavLink className="text-blue" to="/memorygallery">View All</NavLink>
            </div> */}
          </div>
        </div>
        <div>
        </div>
        <div className='gallery'>
          {data.map((item, index) => { 
            return (
              <div className='pics' keys={index} onClick={() => getImg(item.imgSrc)}>
                <img src={item.imgSrc} style={{ width: '100%' }} />
              </div>
            )
          })}
        </div>
        <div className='text-center'>
         {/*<HashLink smooth to='/memorygallery' className='btn btn-blue' target='_blank'> View All </HashLink>
          <NavLink to="/memorygallery" className='btn btn-blue'>View More </NavLink> */}
          </div>
      </section>
    </>
  )
}

export default Gallery
