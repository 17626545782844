import React from 'react';
import ContactForm from './components/ContactForm';
import { NavLink } from "react-router-dom";
//import { useParams } from "react-router-dom";
const Footer = () => {
  //const { name } = useParams();
  return (
    <>

      <section id={'section1'}>
<div className='footer-main' id="contact">
<div className='container'>
<div className='row'>
                <div className='col-sm-6'>
                    <h2>GET IN TOUCH</h2>
                </div>
            </div>
            </div>
<div className='footer'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <h2 className='font-bold'>Mr. Rakesh Jain</h2>
                    <ul className='social-icon'>
                      <li><a href='https://www.facebook.com/rakesh.jain.7161953' target={'_blank'}><i className='fa fa-facebook'></i></a></li>
                      <li><a href='https://twitter.com/Rakesh_CCOP' target={'_blank'}><i className='fa fa-twitter'></i></a></li>
                      <li><a href='https://www.instagram.com/rakeshjain_17/' target={'_blank'}><i className='fa fa-instagram'></i></a></li>
                      <li><a href='https://www.linkedin.com/in/rakeshjain17' target={'_blank'}><i className='fa fa-linkedin'></i></a></li>
                      <li><a href='https://www.youtube.com/channel/UCclfOAGbc31eCtX1aRfFu8A/' target={'_blank'}><i className='fa fa-youtube'></i></a></li>
                    </ul>
                    <div className='clearfix'></div>
                    <p><i className='fa fa-phone'></i><a href='callto:+919826076770'> +919826076770</a></p>
                    <p><i className='fa fa-envelope-o'></i> <a href='mailto:rakesh@infocratsweb.com'>rakesh@infocratsweb.com</a></p>
                 {/* <p><a href='https://www.infocratsweb.com/social/social-contributions.pdf' className='btn btn-white-border' target={'_blank'}>DownloadSocial Profile</a></p> */}
                    <p className='font-12'>Copyright &copy; 2022 | All Rights Reserved</p> 
                    
                    <NavLink className="nav-link" to="/Journey">Journey At A Glance</NavLink>
                </div>
                <div className='col-sm-6'>
                  <ContactForm />
                </div>
            </div>
        </div></div></div>
      </section>
    </>
  )
}

export default Footer
