import React, { forwardRef } from 'react'
import HTMLFlipBook from "react-pageflip";
import Footer from '../Footer';
import Navbar from '../navbar';
export default function Socialprofile() {
    const [soundOn, setSoundOn] = React.useState(true);
    //const [play] = useSound(pageFlipSFX);
    const flipbook = React.useRef(null);
    console.log(forwardRef);

    // const onFlip = React.useCallback(
    //   (e) => {
    //     soundOn && play();
    //   },
    //   [play, soundOn]
    // );

    const flipBack = React.useCallback(() => {
        const pageFlipObj = flipbook.current.pageFlip();
        if (pageFlipObj?.getCurrentPageIndex() === 0) {
            pageFlipObj.flip(pageFlipObj?.getPageCount() - 1);
        } else {
            pageFlipObj.flipPrev();
        }
    }, [flipbook]);

    const flipForward = React.useCallback(() => {
        const pageFlipObj = flipbook.current.pageFlip();
        if (
            pageFlipObj?.getCurrentPageIndex() + 2 ===
            pageFlipObj?.getPageCount()
        ) {
            pageFlipObj.flip(0);
        } else {
            pageFlipObj.flipNext();
        }
    }, [flipbook]);

    return (
        <>
            <Navbar />
            <section>
                <div className='inner-banner'>
                <img src='./images/social-profile.jpg'  />
                </div>

                <div className='container'>
                    <h2 onClick={() => setSoundOn((prev) => !prev)}>
                    </h2>
                    <span >
                        <div className='text-center mb6'>
                            <div className='btn btn-red'
                                onClick={flipBack}
                            >
                                {" "}
                                Prev Page
                            </div>
                            <div
                                className='btn btn-border'
                                onClick={flipForward}
                            >
                                {" "}
                                Next Page{" "}
                            </div>
                        </div>
                        <div className='container'>
                            <h5 className='text-center mb6'> Ground Breaking Social Contributions - Mr. Rakesh Jain</h5>
                        </div>
                     <div className="flipbook">
                            <HTMLFlipBook
                                ref={flipbook}
                                width={200}
                                height={500}
                                size="stretch"
                                showCover={true}
                                drawShadow={false}
                                flippingTime={750}
                                useMouseEvents={false}>
                                <div className="page">
                               
                                <img src='./images/profile/01.jpg' />
                                </div>
                                <div className="page">
                                   
                                <img src='./images/profile/02.jpg' />
                                </div>
                                <div className="page"><img src='./images/profile/03.jpg' /></div>
                                <div className="page"><img src='./images/profile/04.jpg' /></div>
                                <div className="page"><img src='./images/profile/05.jpg' /></div>
                                <div className="page"><img src='./images/profile/06.jpg' /></div>
                                <div className="page"><img src='./images/profile/07.jpg' /></div>
                                <div className="page"><img src='./images/profile/08.jpg' /></div>
                                <div className="page"><img src='./images/profile/09.jpg' /></div>
                                <div className="page"><img src='./images/profile/10.jpg' /></div>
                                <div className="page"><img src='./images/profile/11.jpg' /></div>
                                <div className="page"><img src='./images/profile/12.jpg' /></div>
                                <div className="page"><img src='./images/profile/13.jpg' /></div>
                                <div className="page"><img src='./images/profile/14.jpg' /></div>
                                <div className="page"><img src='./images/profile/15.jpg' /></div>
                                <div className="page"><img src='./images/profile/16.jpg' /></div>
                                <div className="page"><img src='./images/profile/17.jpg' /></div>
                                <div className="page"><img src='./images/profile/18.jpg' /></div>
                                <div className="page"><img src='./images/profile/19.jpg' /></div>
                                <div className="page"><img src='./images/profile/20.jpg' /></div>
                                <div className="page"><img src='./images/profile/21.jpg' /></div>
                                <div className="page"><img src='./images/profile/22.jpg' /></div>
                                <div className="page"><img src='./images/profile/23.jpg' /></div>
                                <div className="page"><img src='./images/profile/24.jpg' /></div>
                                <div className="page"><img src='./images/profile/25.jpg' /></div>
                                <div className="page"><img src='./images/profile/26.jpg' /></div>
                                <div className="page"><img src='./images/profile/27.jpg' /></div>
                                <div className="page"><img src='./images/profile/28.jpg' /></div>
                                <div className="page"><img src='./images/profile/29.jpg' /></div>
                                <div className="page"><img src='./images/profile/30.jpg' /></div>
                                <div className="page"><img src='./images/profile/31.jpg' /></div>
                                <div className="page"><img src='./images/profile/32.jpg' /></div>
                                <div className="page"><img src='./images/profile/33.jpg' /></div>
                                <div className="page"><img src='./images/profile/34.jpg' /></div>
                                <div className="page"><img src='./images/profile/35.jpg' /></div>
                                <div className="page"><img src='./images/profile/36.jpg' /></div>
                                <div className="page"><img src='./images/profile/37.jpg' /></div>
                                <div className="page"><img src='./images/profile/38.jpg' /></div>
                                <div className="page"><img src='./images/profile/39.jpg' /></div>
                                <div className="page"><img src='./images/profile/40.jpg' /></div>
                                <div className="page"><img src='./images/profile/41.jpg' /></div>
                                <div className="page"><img src='./images/profile/42.jpg' /></div>
                                <div className="page"><img src='./images/profile/43.jpg' /></div>
                                <div className="page"><img src='./images/profile/44.jpg' /></div>
                                <div className="page"><img src='./images/profile/45.jpg' /></div>
                                <div className="page"><img src='./images/profile/46.jpg' /></div>
                                <div className="page"><img src='./images/profile/47.jpg' /></div>
                                <div className="page"><img src='./images/profile/48.jpg' /></div>
                                <div className="page"><img src='./images/profile/49.jpg' /></div>
                                <div className="page"><img src='./images/profile/50.jpg' /></div>
                                <div className="page"><img src='./images/profile/51.jpg' /></div>
                                <div className="page"><img src='./images/profile/52.jpg' /></div>
                                <div className="page"><img src='./images/profile/53.jpg' /></div>
                                <div className="page"><img src='./images/profile/54.jpg' /></div>
                                <div className="page"><img src='./images/profile/55.jpg' /></div>
                                <div className="page"><img src='./images/profile/56.jpg' /></div>
                                <div className="page"><img src='./images/profile/57.jpg' /></div>
                                <div className="page"><img src='./images/profile/58.jpg' /></div>
                                <div className="page"><img src='./images/profile/59.jpg' /></div>
                                <div className="page"><img src='./images/profile/60.jpg' /></div>
                                <div className="page"><img src='./images/profile/61.jpg' /></div>
                                <div className="page"><img src='./images/profile/62.jpg' /></div>
                                <div className="page"><img src='./images/profile/63.jpg' /></div>
                                <div className="page"><img src='./images/profile/64.jpg' /></div>
                                <div className="page"><img src='./images/profile/65.jpg' /></div>
                                <div className="page"><img src='./images/profile/66.jpg' /></div>
                                <div className="page"><img src='./images/profile/67.jpg' /></div>
                                <div className="page"><img src='./images/profile/68.jpg' /></div>
                                <div className="page"><img src='./images/profile/69.jpg' /></div>
                                <div className="page"><img src='./images/profile/70.jpg' /></div>
                                <div className="page"><img src='./images/profile/71.jpg' /></div>
                                <div className="page"><img src='./images/profile/72.jpg' /></div>
                                <div className="page"><img src='./images/profile/73.jpg' /></div>
                                <div className="page"><img src='./images/profile/74.jpg' /></div>
                                <div className="page"><img src='./images/profile/75.jpg' /></div>
                                <div className="page"><img src='./images/profile/76.jpg' /></div>
                                <div className="page"><img src='./images/profile/77.jpg' /></div>
                                <div className="page"><img src='./images/profile/78.jpg' /></div>
                                <div className="page"><img src='./images/profile/79.jpg' /></div>
                                <div className="page"><img src='./images/profile/80.jpg' /></div>
                                <div className="page"><img src='./images/profile/81.jpg' /></div>
                                <div className="page"><img src='./images/profile/82.jpg' /></div>
                                <div className="page"><img src='./images/profile/83.jpg' /></div>
                                <div className="page"><img src='./images/profile/84.jpg' /></div>
                                <div className="page"><img src='./images/profile/85.jpg' /></div>
                                <div className="page"><img src='./images/profile/86.jpg' /></div>
                                <div className="page"><img src='./images/profile/87.jpg' /></div>
                                <div className="page"><img src='./images/profile/88.jpg' /></div>
                                <div className="page"><img src='./images/profile/89.jpg' /></div>
                                <div className="page"><img src='./images/profile/90.jpg' /></div>
                                <div className="page"><img src='./images/profile/91.jpg' /></div>
                                <div className="page"><img src='./images/profile/92.jpg' /></div>
                                <div className="page"><img src='./images/profile/93.jpg' /></div>
                                <div className="page"><img src='./images/profile/94.jpg' /></div>
                                <div className="page"><img src='./images/profile/95.jpg' /></div>
                                <div className="page"><img src='./images/profile/96.jpg' /></div>
                                <div className="page"><img src='./images/profile/97.jpg' /></div>
                                <div className="page"><img src='./images/profile/98.jpg' /></div>
                                <div className="page"><img src='./images/profile/99.jpg' /></div>
                                <div className="page"><img src='./images/profile/100.jpg' /></div>
                                <div className="page"><img src='./images/profile/101.jpg' /></div>
                                <div className="page"><img src='./images/profile/102.jpg' /></div>
                                <div className="page"><img src='./images/profile/103.jpg' /></div>
                                <div className="page"><img src='./images/profile/104.jpg' /></div>
                                <div className="page"><img src='./images/profile/105.jpg' /></div>
                                <div className="page"><img src='./images/profile/106.jpg' /></div>
                                <div className="page"><img src='./images/profile/107.jpg' /></div>
                                <div className="page"><img src='./images/profile/108.jpg' /></div>
                                <div className="page"><img src='./images/profile/109.jpg' /></div>
                                <div className="page"><img src='./images/profile/110.jpg' /></div>
                                <div className="page"><img src='./images/profile/111.jpg' /></div>
                                <div className="page"><img src='./images/profile/112.jpg' /></div>
                                <div className="page"><img src='./images/profile/113.jpg' /></div>
                                <div className="page"><img src='./images/profile/114.jpg' /></div>
                                <div className="page"><img src='./images/profile/115.jpg' /></div>
                                <div className="page"><img src='./images/profile/116.jpg' /></div>
                                <div className="page"><img src='./images/profile/117.jpg' /></div>
                                <div className="page"><img src='./images/profile/118.jpg' /></div>
                                <div className="page"><img src='./images/profile/119.jpg' /></div>
                                <div className="page"><img src='./images/profile/120.jpg' /></div>
                                <div className="page"><img src='./images/profile/121.jpg' /></div>
                                <div className="page"><img src='./images/profile/122.jpg' /></div>
                                <div className="page"><img src='./images/profile/123.jpg' /></div>
                                <div className="page"><img src='./images/profile/124.jpg' /></div>
                                <div className="page"><img src='./images/profile/125.jpg' /></div>
                                <div className="page"><img src='./images/profile/126.jpg' /></div>
                                <div className="page"><img src='./images/profile/127.jpg' /></div>
                                <div className="page"><img src='./images/profile/128.jpg' /></div>
                                <div className="page"><img src='./images/profile/129.jpg' /></div>
                                <div className="page"><img src='./images/profile/130.jpg' /></div>
                                <div className="page"><img src='./images/profile/131.jpg' /></div>
                                <div className="page"><img src='./images/profile/132.jpg' /></div>
                                <div className="page"><img src='./images/profile/133.jpg' /></div>
                                <div className="page"><img src='./images/profile/134.jpg' /></div>
                                <div className="page"><img src='./images/profile/135.jpg' /></div>
                                <div className="page"><img src='./images/profile/136.jpg' /></div>
                                <div className="page"><img src='./images/profile/137.jpg' /></div>
                                <div className="page"><img src='./images/profile/138.jpg' /></div>
                                <div className="page"><img src='./images/profile/139.jpg' /></div>
                                <div className="page"><img src='./images/profile/140.jpg' /></div>
                                <div className="page"><img src='./images/profile/141.jpg' /></div>
                                <div className="page"><img src='./images/profile/142.jpg' /></div>
                                <div className="page"><img src='./images/profile/143.jpg' /></div>
                                <div className="page"><img src='./images/profile/144.jpg' /></div>
                                <div className="page"><img src='./images/profile/145.jpg' /></div>
                                <div className="page"><img src='./images/profile/146.jpg' /></div>
                                <div className="page"><img src='./images/profile/147.jpg' /></div>
                                <div className="page"><img src='./images/profile/148.jpg' /></div>
                                <div className="page"><img src='./images/profile/149.jpg' /></div>
                            </HTMLFlipBook> 
                        </div> 
                    </span>
                </div>
                <Footer />
            </section>
        </>
    );
}




// const Socialprofile = () => {
//   return (
//     <>
//       mbj
//     </>
//   )
// }

//export default Socialprofile
