import React from 'react';
import Footer from '../Footer';
import Navbar from '../navbar';
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';

const About = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
          <Helmet>
          <title>About</title>
      <meta name="author" content="About Rakesh Jain" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
    <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
      <Navbar />
      <div className='inner-banner'>
        <img src='./images/aboutnew.jpg' />
      {/* <div className='container'>
        
          <div className='left'><h1>About 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      </div>
      <div className='clearfix'></div>
      <section className='inner-content about'>
        <div className='container about-page'>
        <div className='row m-0 p-0'>
        <div className='col-sm-6 text-center'> <img src='./images/main-img.jpg' /></div>
              <div className='col-sm-6'>
          <p>Mr. Rakesh Jain has achieved the aim of touching over 10 million lives, by affecting them positively through the innovative use of technology! Constantly striving to make a social impact, Rakesh Jain has taken initiatives toward various social causes.</p>
          <p> From women's safety and citizen empowerment to food wastage, promoting quality of education to tackling pollution problems, plantation with public participation to working on juvenile justice and cybercrime awareness and prevention to providing relief in the early phases of COVID through meal distribution, Mr. Rakesh Jain’s work has been multi-dimensional.</p>
          <p>He believes in leveraging technology as a platform and taking steps towards building a smart nation.
          </p>
          </div>
          
          </div>
        </div>
        <div className='gray-bg'>
          <div className='container'>
            <div className='row m-0 p-0'>
              <div className='col-sm-6'>
                <h3>Infocrats Web Solutions PVT LTD</h3>
                <p>As the director of INFOCRATS Web Solutions Pvt. Ltd, a global software consulting company headquartered in Indore, India, Rakesh Jain says that it has been his sole intention to use technology in a way that positively impacts a giant multitude of citizens.
                </p>
                <p>Therefore, Mr Rakesh Jain always focuses all his resources towards discovering innovative ways to use technology while inventing more of them too.
                </p> </div>
              <div className='col-sm-6 text-center'>
                <img src='./images/infocrats1.png' />
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 text-center'>
              <img src='./images/quacito1.jpg' />
            </div>
            <div className='col-sm-6'>
              <h3>Quacito LLC</h3>
              <p> As the COO of Quacito LLC, Mr. Rakesh Jain has made his technological impacts in the US as well. </p>
              <p>His efforts have helped multi-national pest control industries, in automating their daily processes. </p>
              <p>Mr. Rakesh Jain has studied the global market well enough to serve parties there, and make them reach their business milestones, effortlessly.</p> </div>
          </div>
          </div>
        <div className='gray-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <h3>CitizenCOP Foundation</h3>
                <p>  As the founder, Mr. Rakesh Jain has built it on the three pillars of citizen safety, empowerment, and convenience. It is a platform where even the citizens can rise to the occasion and help law enforcement agencies. A CitizenCop is ANYONE who helps an elderly, saves a woman in danger, protects a child, and does any act of safety and kindness towards the other. Rakesh Jain has made sure that the platform uses technological innovations to create benchmarks for solving social causes, focussing on issues like </p>
                <ul className="custom-list"> <li> Safety & Security of every Citizen</li>
                  <li>  Safety of Women and Children with 24*7 Assistance across INDIA</li>
                  <li> Supporting Education System through Performance Analysis System</li>
                  <li>  Preventing Child Abuse Preventing Child Trafficking Rehabilitating Children in NEED</li>
                  <li> Enhancing Interpersonal Skills The happiness of Underprivileged Children Making every city CLEAN & SMART</li>
                  <li>  Conservation and preservation of trees</li>
                  <li>  Creating and spreading awareness about the pollution levels</li>
                </ul></div>
              <div className='col-sm-6 text-center'>
                <img src='./images/citizencop.png' />
              </div>
            </div></div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 text-center'>
              <img src='./images/greengene1.jpg' />
            </div>
            <div className='col-sm-6'>
              <h3>greenGENE</h3>
              <p>  Mr. Rakesh Jain has come up with one of the most significant initiatives of the CitizenCOP foundation; greenGENE.
              </p><p>
                It provides physical and technological assistance to plant and QR tag trees to maintain their health and provide geo tagging.
              </p><p>
                This unique QR code allows tree census, viewing trees' growth and maintenance via pictures, and establishes accountability towards the trees while motivating public participation.
              </p><p>
                Rakesh Jain has also made sure that greenGENE aids in carbon credit calculation and the creation of green wealth. greenGENE also motivates the appreciating gift of trees rather than any other depreciating gift.
              </p>
            </div>
            {/* <p><NavLink to="/Flipbook" className='text-danger'>Flip Book <i className='fa fa-long-arrow-right'></i> </NavLink></p> */}

           
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default About
