import React, { useState } from 'react';
import awardapi from '../API/awardApi';
import Footer from '../Footer';
import Navbar from '../navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';

const MilestonePage = () => {
    const [milestoneData, setMilestoneData] = useState(awardapi);
  return (
    <>
         <Helmet>
         <title>Achievements</title>
      <meta name="author" content="Award" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
    <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
      <Navbar />
      <div className='inner-banner'>
      {/* <div className='container'>
        
          <div className='left'><h1>Milestone 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      <img src='./images/milestonenew.jpg' />
      </div>
      <div className='clearfix'></div>
      <section className='inner-content'>
        <div className='container'>
      
          {milestoneData.map((curElem) => {
            const { Link, id, title, image1, image2, image3, info } = curElem;
            return (<>
              <div key={id} className="inner-work-div mt5 mb5">
               
                <div className='row'>
                  <div className='col-sm-4'>
                    <img src={image1} alt="Award" />
                  </div>
                 <div className='col-sm-8'> 
                 <h5>{title}</h5>
                <p>{info}</p></div>   
                </div>
              </div>
            </>);
          })}
        </div>
      </section>
      <Footer />
    </>
  )
}

export default MilestonePage
