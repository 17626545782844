import React from 'react';
import Footer from '../Footer';
import Navbar from '../navbar';
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';

const Journey = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
          <Helmet>
          <title>Journey at A Glance </title>
      <meta name="author" content="About Rakesh Jain" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
    <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
      <Navbar />
      <div className='inner-banner'>
        <img src='./images/glance.png' />
      
      </div>
      <div className='clearfix'></div>
      <section className='inner-content journey'>
        <div className='container about-page'>
        <div className='row m-0 p-0'>
        <div className='col-sm-12'>
        Mr. Rakesh Jain is the Director of INFOCRATS Web Solutions Pvt. Ltd., a global software consulting firm based in Indore, India, which he founded in 1996. He holds both a Bachelor's and Master's degree in Engineering from SGSITS, Indore.
Passionate about social impact, Rakesh Jain has spearheaded numerous initiatives, addressing cyber awareness, women’s safety, food waste, and promoting education. His work spans various social causes, always aiming to create positive change. He believes in harnessing technology to build a smarter, safer nation, using innovation to bridge the gap between those in need and the resources that can help them.
<h5 className='mt6'>Professional Experience</h5>
With over two decades of entrepreneurial experience as the founder of INFOCRATS Web Solution Pvt. Ltd., Rakesh Jain has served as an IT consultant for numerous corporate, government, and educational institutions globally. His innovative approach and passion for turning ideas into reality have been pivotal in the company's success, leading to a portfolio of groundbreaking projects. Rakesh believes in leveraging teamwork and innovation as the foundation of success, dedicating his efforts to enhancing the company’s reputation and growth.
Over the past decade, he has expanded his focus to social causes, using technology creatively to address key issues like organ donation, food distribution, education, and public safety. His initiatives, such as CitizenCOP, Softclicks, Aahar, greenGENE, and CyberCOP, have gained national recognition for their impact.
Rakesh Jain is now a strong advocate for social entrepreneurship, believing in the power of youth development to drive national progress. His mission is to mentor and guide young entrepreneurs in achieving their dreams, fostering a brighter future for the country.
<h5 className='mt6'>About INFOCRATS Web Solutions</h5>
Bringing process automation to businesses, INFOCRATS provides specially curated solutions to help accelerate business growth for a diverse set of industries. With years of dedicated and focused endeavors, it is now a full-grown IT company with a market foothold of more than a decade. INFOCRATS Web Solutions has a sister concern named Quacito LLC in San Antonio, Texas.
The innovative approach and utilization of the latest Trends & Technologies enables INFOCRATS to provide professionally designed web solutions to its customers. 
INFOCRATS has the expertise of developing the most effective and interactive websites, and mobile applications, for its corporate clients, to make the customers sit on the edge of their seats and take notice of the organization. It offers website development, customized software development, mobile applications development, and Internet Marketing services to meet the client's business needs. INFOCRATS has exceptional in-house teams of PHP, ASP.Net, Android, iOS, and Internet Marketing that have been handpicked for excellence.
<h5 className='mt6'>Foundations</h5>
<p><b>CitizenCOP Foundation – </b>Founded by Mr. Rakesh Jain, CitizenCOP is built on the pillars of citizen safety, empowerment, and convenience. This NGO empowers citizens to assist the police in ensuring public safety. With over 20 features, the CitizenCOP app transforms ordinary users into "citizen cops," helping bridge the gap between citizens and law enforcement. A significant initiative of this foundation is greenGENE, which provides physical and technological support for tree planting and monitoring. Through QR tagging, users can track tree health and growth, promoting accountability and environmental conservation.
</p><p><b>CyberCOP Foundation –</b> Co-founded by Mr. Rakesh Jain, CyberCOP is dedicated to spreading awareness about cybercrime and helping victims of cyberattacks. The foundation focuses on preventing online abuse, especially of women, teenagers, and children, offering support through a child helpline, legal aid, and fake news detection tools.
</p><p><b>Aahar Foundation –</b> Mr. Jain co-founded Aahar to address hunger in India. Through an app, food donors are connected with volunteers and NGOs who distribute food to those in need. Donors receive real-time notifications, including photos and geo-locations of food distribution. The foundation accepts both surplus and fresh food donations.
  </p>   </div>     
          </div>
        </div>
        <div className='gray-bg'>
          <div className='container'>
           <h5 className='mt6'>Milestones
           </h5>
           <ul className="custom-list">
<li><b>CitizenCOP:</b> Downloaded over 600,000 times, assisting more than 1 million lives through SOS services, traffic challan payments, and mobile recoveries.</li>
<li><b>greenGENE:</b> Over 450,000 trees have been QR-tagged, with more than 2,000 trees planted in individuals’ names, ensuring their survival through dedicated care.</li>
<li><b>Vidyadaan: </b>In 2 years, volunteers delivered 24,000 lecture hours, creating a social impact valued at ₹20-30 crores.</li>
</ul>
<h5 className='mt6'>Accomplishments</h5>
<p><b>On-field Accomplishments during COVID-19 </b>– Through the CoronaCOP kitchen, over 250,000 meals, valued at more than ₹50 lakh, were distributed during the pandemic's early phases.</p>
<p><b>Technical Accomplishments during COVID-19 </b>– Developed critical applications, including tools for Corona self-tests, alerts, crisis reporting, e-pass generation, and tracking nearby Corona patients using map markers.</p>
          </div>
        </div>
        <div className='container'>
        <h5 className='mt6'>Other Notable Achievements:</h5>
<ul className="custom-list">
<li>
Presented the eAttendance app module at NIRD Hyderabad with Indore Divisional Commissioner Shri Sanjay Dubey.</li>
<li>Contributed to discussions on Juvenile Justice at regional and state conferences organized by MP High Court and WCD Madhya Pradesh.</li>
<li>Mentored young entrepreneurs at IIM Indore's incubation centre.</li>
<li>Delivered smart policing sessions for national police officers at CAPT Bhopal.</li>
<li>Conducted cybercrime awareness sessions, particularly for women, children, and senior citizens.</li>
<li>Led the "Chalo Padhaye Desh Badhaye" campaign alongside Indore DIG and Indore Police, impacting 100,000 students across 500 schools in Indore.</li>
<li>Over 58,000 students registered in the past three years for Vidhyadaan, aimed at enhancing education quality in government schools.</li>
<li>Helped feed over 150,000 people through Aahar, a platform for food donations to the underprivileged.</li>
<li>Developed a smart attendance tool used by over 78,000 teachers for marking attendance via smartphones.</li>
</ul>

<h5 className='mt6'>Appreciations</h5>
<ul className="custom-list">
<li>Recognized by Mrs. Sumitra Mahajan, Indore Mayor Malini Gaur, and the Director General of Police, Chhattisgarh, for various contributions, including CitizenCOP and Swachh Indore campaigns.</li>
</ul>
          </div>
          <div className='container'>
       <h5 className='mt6'>Awards and Recognitions</h5>
	   <p>
Rakesh Jain has been conferred several awards at state, national & international level forums for developing CitizenCOP:</p>

<ul className="custom-list">
<li>The <b>Digital India Awards 2016</b> under the Best Mobile Application category for <b>CitizenCOP</b>. The award was presented by Hon’ble Union Minister of Electronics and Information Technology and Law & Justice Ravi Shankar Prasad.</li>
<li><b>e-Governance Award 2013-2014</b> for <b>CitizenCOP</b> by Honorable Chief Minister of MP Mr Shivraj Singh Chouhan for Excellence by MP Government.</li>
<li><b>e-Governance Award 2014-2015</b> for Excellence in the Best IT initiative for <b>eAttendance App</b> </li>
<li><b>e-Governance Award 2013-2014 </b>for Excellence in the best IT initiative for <b>GRP Help App</b></li>
<li><b>The Mobile for Good Award</b> by Vodafone Foundation 2014</li>
<li><b>India Merchant’s Chamber </b>(IMC) Mumbai for innovative use of technology</li>
<li><b>mBillionth</b> South Asia Innovative Mobile Technology Awards 2014</li>
<li><b>Salam Madhya Pradesh</b> Award by Madhya Pradesh Police</li>
<li><b>Indian Leadership Award</b> For Industrial Development by All India Achievers Foundation</li>
<li><b>Indian Achievers’ Award</b> for Social Service by Indian Achievers Forum</li>
<li>Received <b>Acharya Anand Yuwa Samman</b>-2015</li>
</ul>
<p>Looking for an introduction to Rakesh Jain? Access his <a href="./images/glance-summery.pdf" target="_blank">short bio.</a></p>
          </div>
    
 
      </section>
      <Footer />
    </>
  )
}

export default Journey
