import React from 'react';
import { NavLink } from "react-router-dom";
//import AOS from 'aos';
//import 'aos/dist/aos.css';
import {useEffect} from "react";
const About = () => {

        (
    <>
      <section className='home-about'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 text-center'>
              <img src='./images/about-img.png' />
            </div>
            <div className='col-sm-6 right'>
              {/* <h5 className='mb4'>Social Entrepreneur and Philanthropist</h5> */}
              <h2 className='mb5'>About Mr. Rakesh Jain</h2>
              <p> In the past decades, Mr. Rakesh Jain has consulted several businesses in various domains across the globe. His core area of expertise is IT Implementation and smoothening business processes. His passion and interest in social initiatives have given him the experience of working with top bureaucrats of different states.
              </p><p><NavLink to="/about" className='text-blue'>Read More <i className='fa fa-long-arrow-right'></i> </NavLink></p>
                            
              <div className='details'>
                <table className='table table-striped'>
                  <tr>
                    <th width="35%">Director</th>
                    <td>INFOCRATS Web Solutions</td>
                  </tr>
                  <tr className='light-blue-bg'>
                    <th>COO</th>
                    <td>Quacito LLC</td>
                  </tr>
                  <tr>
                    <th> Founder</th>
                    <td>CitizenCOP Foundation, greenGENE</td>
                  </tr>
                  <tr className='light-blue-bg'>
                    <th>Co-Founder</th>
                    <td>Aahar Foundation, SoftClicks Foundation, DREAM Service Software</td>
                  </tr>
                  
                </table>
              </div>
              <p><NavLink to="/Socialprofile" className='btn btn-blue mt-2'>Social Profile <i className='fa fa-long-arrow-right'></i> </NavLink></p>
              {/* <a href='https://www.infocratsweb.com/social/social-contributions.pdf' className='btn btn-red btn-big mt-1' target={'_blank'}>Social Profile</a> */}
            </div>

            <div className='clearfix'> </div>
          </div>


        </div>
      </section>
    </>
  )
}

export default About
