import React, { useState, useEffect } from 'react';
import "./slider.scss";
import {Sliderdata} from "./slider-data";


const Slider = () => {
    
const [currentSlide, setCurrentSlide] = useState(0);
const slideLength = Sliderdata.length;
// slideLength = 1 2 3
//currentSlide = 0 1 2

const autoScroll = true;
let slideInterval;
let intervalTime =15000;

const nextSlide =() =>{
    setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide + 1);
};
const prevSlide =() =>{
    setCurrentSlide(currentSlide === 0 ?  slideLength - 1 : currentSlide - 1);
};

function auto(){
    slideInterval = setInterval(nextSlide, intervalTime)
}
useEffect(() => {
    setCurrentSlide(0)
}, [])

useEffect(() => {
   if (autoScroll){
    auto();
   }
   return () => clearInterval(slideInterval);
}, [currentSlide]);

  return (
    <div className='slider'>
      <i className="fa fa-chevron-left arrow prev" onClick={prevSlide}></i>
      <i className="fa fa-chevron-right arrow next" onClick={nextSlide}></i>
      {Sliderdata.map((slide, index) => {
        return(
            <div className={index === currentSlide ?  "slide current" : "slide" } key={index}>
                {index === currentSlide && (
                    <div>
                    <img src={slide.image} alt="test" />
                    <div className="content">
                        <h2>{slide.heading}</h2>
                        <div className='desc'>{slide.desc}</div>
                        {/* <a href={slide.link} class="btn btn-border" target={'_blank'}>Get Started</a> */}
                    </div>
                    </div>
                )}
            </div>
        )
      })}
    </div>
  )
}

export default Slider
