import React, { Component } from 'react';
//import Footer from '../Footer';
//import Navbar from '../navbar';
import Lightroom from 'react-lightbox-gallery';

// const Memories = () => {
//   return (
//     <>
//     <Navbar />
//     </>
//   )
// }
export default class Memories extends Component {
  render() {
    var images = [
      {
        src: "../images/event-1.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-2.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },

      {
        src: "../images/event-6.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-4.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },
      {
        src: "../images/event-5.png",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-9.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },
      {
        src: "../images/event-7.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },
      {
        src: "../images/event-8.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/ankush-app1.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },
      {
        src: "../images/ankush-app2.jpg",
        //desc: 'Blonde woman wearing sunglasses smiling at the camera ',
        //sub: 'Dmitriy Frantsev'
      },
      {
        src: "../images/event-3.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-10.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-11.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-38.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-12.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-14.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-13.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-15.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-16.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-17.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
     
      {
        src: "../images/event-199.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/aahar-launch.jpeg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-21.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-22.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-23.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-24.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-29.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-30.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-33.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-34.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      },
      {
        src: "../images/event-36.jpg",
        //desc: 'Person wearing shoes',
        //sub: 'Gift Habeshaw'
      }
    ]
    var settings = {
      columnCount:{
        default:4,
        mobile:1,
        tab:3,
      },
      mode: 'dark'
    }
    return (
      <div className='inner-gallery'>
        <Lightroom images={images} settings={settings} />
      </div>
    )
  }
}




{/*const Memories = () => {
  return (
    <>
    
     <Navbar />
    <div className='inner-banner memories'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <h1>Memories</h1>
              <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non quaerat inventore ullam, nobis dolor reiciendis saepe </h5>
            </div>
          </div>
        </div>
      </div>
      <div className='clearfix'></div>
      <section className='inner-content'>
      <div className='container'>
      
      </div>
      </section>
      <Footer /> 
    </>
  )
}*/}

//export default Memories
