//import React from 'react';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


const ContactUs = () => {
    const form = useRef(); 
    const [formStatus, setFormStatus] = React.useState('Submit')
    const sendEmail = (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');
        emailjs.sendForm('service_9f4m1pg', 'template_gg6htfe', form.current, 'kSQW0cyg2CPY9HiLM')
            .then((result) => {
                console.log(result.text);
                setFormStatus('Submit');
                document.getElementById("create-course-form").reset();
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <div className='contact-form'>
          
            <form id="create-course-form" ref={form} onSubmit={sendEmail}>

                <div className='form-group' htmlFor="name">
                    <input type="text" className='form-control tfield1' placeholder='Name' id="name" name="user_name" required />
                </div>
                <div className='form-group' htmlFor="email">
                    <input type="email" className='form-control tfield2' placeholder='Email' name="user_email" id="email" required />
                </div>
                <div className='form-group' htmlFor="subject">
                    <input type="text" className='form-control tfield3' placeholder='Subject' name="user_subject" id="subject" required />
                </div>
                <div className='form-group' htmlFor="message">
                    <textarea cols="10" className='form-control tfield4' rows="3" placeholder='Type Your Message' name="message" id="message" required></textarea>
                </div>
                <div className='form-group'>
                    <button className='btn btn-red btn-big'>{formStatus}</button>

                </div>
            </form>
        </div>
    )
}
export default ContactUs