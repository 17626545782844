import React, { useState } from 'react';
import initiativeapi from './API/initiativeApi.js';
import secondinitiativeapi from './API/secondinitiativeApi.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { HashLink } from 'react-router-hash-link';

const Initiatives = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const [initiativeData, setinitiativeData] = useState(initiativeapi);
  const [secondinitiativeData, setsecondinitiativeData] = useState(secondinitiativeapi);
  //expand div code
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };
  //expand div code
  
  //const [isExpanded, setIsExpanded] = useState(false);



  return (
    <>
   <section className="intiative-main-container" >
        <div className="container intiative-container" >
         
          {/* <h5 className='mb5'>Mr. Rakesh Jain is Working Across Diverse Sectors That Need Attention</h5> */}
          {/* <h4 className="fw-bold text-center"> */}
          <div className='row'>
            <div className='col-sm-9'> <h4 className="fw-bold">
          INITIATIVES BY MR. RAKESH JAIN<br/>
          <img src='./images/heading-img2.png' /> </h4></div>
            {/* <div className='col-sm-3 text-right'> <HashLink smooth to='/portfolio' target='_blank' className='text-white'> View All  <i className='fa fa-long-arrow-right'></i></HashLink></div> */}
          </div>
         
    
          {/* <img src='./images/heading-img2.png' /> */}
        


          <div>
      <div className="default-divs ">
      <div className='row' style={{ marginBottom: '10px' }}>
        {Array.from({ length: 1 }, (_, index) => (
<>
            <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="http://www.citizencop.org/" target={'_blank'}> <img src="./images/icon1.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">CitizenCOP</h5>
                    <p className="main-hero-para">A Digital India award-winning application, built on safety, empowerment, and convenience recommended by BPR&D, MHA, GOI to all state government, DGPs and IGs.</p>
                  </div></div></div>
                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.cybercop&gl=GB" target={'_blank'}> <img src="./images/icon7.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">CyberCOP</h5>
                    <p className="main-hero-para">The go-to solution for fighting cybercrime, empowering users to protect themselves online, and boosting awareness, all in one user-friendly platform.</p>
                  </div></div></div>
                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="http://greengene.citizencop.org/" target={'_blank'}> <img src="./images/icon2.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">greenGENE</h5>
                    <p className="main-hero-para">A green revolution initiative executing plantation with public participation, giving QR code identity to trees, record keeping, and improving their survival rate.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="http://www.aahar.org.in/" target={'_blank'}> <img src="./images/icon3.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Aahar</h5>
                    <p className="main-hero-para">An innovative mobile app that tackles food wastage by spreading awareness, and connecting donors with those in need, ensuring surplus food reaches hungry individuals.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.smartfamily" target={'_blank'}> <img src="./images/icon8.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Smart Family</h5>
                    <p className="main-hero-para">The app offers information related to health, financial literacy, estate planning, and cyber hygiene making families smarter. It provides sample templates that users can fill with their own data regarding documents, wills, and more for their use.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.bloodbank" target={'_blank'}> <img src="./images/icon9.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Blood Buddy</h5>
                    <p className="main-hero-para">Our blood donation app combines compassion and convenience, rewarding your contributions with credits for future transfusions. Join us in sharing life, one drop at a time.</p>
                  </div></div></div>

                 
                </>  
          
        ))}</div>
      </div>
      {showMore && (
        <div className="additional-divs">
          {Array.from({ length: 1 }, (_, index) => (
            <div className='row' key={index + 6} style={{ marginBottom: '10px' }}>
             <>
             <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="#" target={'_blank'}> <img src="./images/icon10.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Ankush</h5>
                    <p className="main-hero-para">Indore police's essential tool. Crime dossier, jail records, preventive actions, and more. Simplifies daily police tasks, empowering officers with comprehensive criminal information. Streamlining Indore's law enforcement efforts for a safer community.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.traffic" target={'_blank'}> <img src="./images/know_your_cop.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Know Your COP</h5>
                    <p className="main-hero-para">Scan QR codes at nearby police stations to instantly access details of duty officers. Get crucial info like Name, Mobile Number, and more right on your device. Used for accountable police patrolling. </p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.sevasetu" target={'_blank'}> <img src="./images/sevasetu.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Seva Setu</h5>
                    <p className="main-hero-para">Seva Setu App is an innovative and practical platform designed to connect the common man with Non-Governmental Organizations (NGOs). Through this app, users and NGOs can register at their convenience and collaborate to accomplish necessary tasks. </p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.citizeneyeapp" target={'_blank'}> <img src="./images/citizen_eye.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Citizen EYE</h5>
                    <p className="main-hero-para">This initiative enables users to report crimes by sharing access to their CCTV cameras. It provides the police with valuable data and saves government expenses on security camera installations.  </p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.eprocop.notebook" target={'_blank'}> <img src="./images/icon11.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">eProCOP</h5>
                    <p className="main-hero-para">A tool designed for the internal use by the police department for proficient, professional and proactive policing. It also brings a level of transparency to the system, giving senior officials better insights.  </p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.fertilizershajapur" target={'_blank'}> <img src="./images/icon4.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Smart Fertilizer</h5>
                    <p className="main-hero-para">Created mainly for the convenience of the farmers. In this application farmers will get the demand and distribution of fertilizers required for crop production through the app.  Farmers and committees will also benefit from the demand and distribution of fertilizers of their choice through the app.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="http://www.vidhyadaan.com/" target={'_blank'}> <img src="./images/icon6.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Vidhya Daan</h5>
                    <p className="main-hero-para">An initiative for professionals to contribute towards development of an educated society, in partnership with government educational departments and civil society organizations. The app allows volunteers to register and select school, class and subject they wish to teach in government school. Students, housewives, working professionals and others can register.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="#"> <img src="./images/icon12.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Juvenile Justice</h5>
                    <p className="main-hero-para">A campaign solving problems like child abuse, child trafficking, child labour and depression and importantly delay in justice. It's a tool developed to create a consolidated platform for all stakeholders involved in the process to streamline the process and offers proper care and justice to juveniles.</p>
                  </div></div></div>

                  <div className="col-12 col-lg-4 col-xxl-4">
                    <div className='work-container-subdiv'>
                    <div className='left'><a href="https://play.google.com/store/apps/details?id=com.info.jalmitra"> <img src="./images/icon5.png" alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    <h5 className="sub-heading">Jal Mitra</h5>
                    <p className="main-hero-para">The app aims to serve as authentic base for information on the city’s lakes . Its an initiative by Divisional Commissioner Indore Shri Akash Tripathi and runs under a registered society in the name of Jheel aiwam Bhu Jal Sanrakshan Society, Indore.</p>
                  </div></div></div>
                  


                
             </> 
            </div>
          ))}
        </div>
      )}
      <p className='text-center'><button onClick={handleToggle} className='btn btn-red btn-big'>
        {showMore ? 'Show Less' : 'Show More'}
      </button></p>
    </div>







          {/* <div className="row">
            {initiativeData.map((curElem) => {
              const { Link, id, title, image, info } = curElem;
              return (
                <>
                  <div
                    className="col-12 col-lg-4 col-xxl-4"
                    key={id}>
                    <div className='work-container-subdiv'>
                    <div className='left'><a href={Link} target={'_blank'}> <img src={image} alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    
                     {/* <img src={initiativeApi.image} alt={`alien ${initiativeApi.eventType}`} /> */}
                    {/* <h5 className="sub-heading">{title}</h5>
                    <p className="main-hero-para">{info}</p> */}
                  {/* <p><a href={Link} target={'_blank'}>Read More <i className='fa-long-arrow-right fa'></i></a></p> */}
                  {/* </div></div></div>
                </>
              );
            })}
          </div> */} 
         <div>
      
      {/* <button onClick={handleShowRemainingData}>View More</button>
      {showRemainingData && (         
         <div className="row">
            {secondinitiativeData.map((curElem) => {
              const { Link, id, title, image, info } = curElem;
              return (
                <>
                  <div
                    className="col-12 col-lg-4 col-xxl-4"
                    key={id}>
                    <div className='work-container-subdiv'>
                    <div className='left'><a href={Link} target={'_blank'}> <img src={image} alt="test" className="ini-img" /></a></div>
                    <div className='right'>
                    
                    
                    <h5 className="sub-heading">{title}</h5>
                    <p className="main-hero-para">{info}</p>
                 
                  </div></div></div>
                </>
              );
            })}
          </div>        
        
      )} */}
    </div> 
        </div>
      </section>
     
    </>
  )
}

export default Initiatives;
