import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <ul className="side-nav">
              <li className="nav-item">
        <NavLink className="nav-link" to="/covid19">Has Social Media Changed You In Covid-19?</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/PackPassionPersistence">Don't Forget to Pack Passion & Persistence If You are Traveling up to Success!</NavLink>
      </li>
      <li className="nav-item">
      <NavLink className="nav-link" to="/heartofanInstitution">How organic living became a turning point in my life!</NavLink>
        
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/Positivesapandemicbrought">The positives a pandemic brought to our lives and to the mother nature.</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/HeartofanInstitution">Alumni can be the pumping heart of an Institution!</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/Howcarboncreditschanginggreenwealth">How carbon credits changing green wealth!!</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/Whatsourfuture">What's our future, if we don't plan it together?</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/Deepfake">Beware of Deep Fakes: A Cyber Security Pro's Take </NavLink>
      </li>
 
              </ul>
    </>
  )
}

export default Sidebar
