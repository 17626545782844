import React, { useState } from 'react';
import Home from "./Home";
import About from "./pages/Aboutpage";
import Award from "./pages/Awardpage";
import Blog from "./pages/Blogdetail";
import Memories from "./pages/Memoriespage";
import Contact from "./pages/Contactpage";
import Portfolio from "./pages/Portfoliopage";
//import { Route, Switch } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.scss';
import Allblog from "./pages/Allblog";
import Videos from "./pages/Videospage";
//import Flipbook from "./pages/Flipbook";
import Socialprofile from "./pages/Socialprofile";
import Memorygallery from "./pages/Memorygallery";
import Covid19 from "./pages/Covid19"; 
import PackPassionPersistence from "./pages/PackPassionPersistence";
import HeartofanInstitution from "./pages/heartofanInstitution";
import OrganicLiving from "./pages/Organicliving";
import Positivesapandemicbrought from "./pages/Positivesapandemicbrought";
import Whatsourfuture from "./pages/Whatsourfuture";
import Howcarboncreditschanginggreenwealth from "./pages/Howcarboncreditschanginggreenwealth";
//import {Helmet} from "react-helmet";
import DeepFake from "./pages/Deepfake";
import MilestonePage from "./pages/MilestonePage";
import Journey from './pages/Journeypage';
const App = () => {
  
  return (
    <>
   
    <Switch>
    <Route exact path='/' component={Home}></Route> 
    <Route path='/about' component={About}></Route> 
    <Route path='/award' component={Award}></Route> 
    <Route path='/milestone' component={MilestonePage}></Route> 
    <Route path='/blog' component={Blog}></Route> 
    <Route path='/memories' component={Memories}></Route> 
    <Route path='/portfolio' component={Portfolio}></Route> 
    <Route path='/contact' component={Contact}></Route> 
    <Route path='/allblog' component={Allblog}></Route> 
    <Route path='/videos' component={Videos}></Route> 
    {/* <Route path='/flipbook' component={Flipbook}></Route>  */}
    <Route path='/socialprofile' component={Socialprofile}></Route> 
    <Route path='/Deepfake' component={DeepFake}></Route> 
    <Route path='/memorygallery' component={Memorygallery}></Route> 
    <Route path='/covid19' component={Covid19}></Route> 
    <Route path='/packpassionpersistence' component={PackPassionPersistence}></Route>
    <Route path='/heartofanInstitution' component={HeartofanInstitution}></Route>
    <Route path='/organicliving' component={OrganicLiving}></Route>
    <Route path='/positivesapandemicbrought' component={Positivesapandemicbrought}></Route>
    <Route path='/Howcarboncreditschanginggreenwealth' component={Howcarboncreditschanginggreenwealth}></Route>
    <Route path='/Whatsourfuture' component={Whatsourfuture}></Route>
    <Route path='/Journey' component={Journey}></Route>
      </Switch> 
    </>
  )
}

export default App
