import React from 'react';
//import $ from '../node_modules/jquery/dist/jquery.min';
import OwlCarousel from 'react-owl-carousel';
import '../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import '../node_modules/owl.carousel/dist/owl.carousel';
import jQuery from 'jquery';
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

window.jQuery = jQuery
require('owl.carousel');

const state = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  autoplayTimeout: 1000,
  autoplayHoverPause: true,
  navText: ["Prev", "Next"],
  smartSpeed: 100,
  center: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    700: {
      items: 4,
    },
    1000: {
      items: 4,
    }
  },

};

class Owlslider extends React.Component {
  render() {
    return (
      <section>

        <div className=' award' >
          <div className='container'>
            <div className='row m-0'>
              <div className='col-6'><h3 className='fw-bold'>AWARDS & RECOGNITION</h3></div>
              <div className='col-6 text-right'>
              <HashLink smooth to='/milestone' target='_blank'> View All  <i className='fa fa-long-arrow-right'></i></HashLink>
                {/* <NavLink className="text-blue" to="/award">View All</NavLink> */}
              </div>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col-sm-1 col-12 d-none d-sm-block'></div>
            
            <div className='col-sm-11 col-12 p-0'>
              <OwlCarousel className="owl-theme " loop autoplay={true} touchDrag={true} margin={30} autoplayTimeout={2000} responsive={state.responsive} dots={false} nav>
                <div class="item">
                  <img src="./images/award-1.jpg" alt="Avatar" />
                  <h4>Digital India Awards 2016</h4>
                  {/* <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-1.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="item">
                  <img src="./images/award-2.jpg" alt="Avatar" />
                  <h4>FICCI Award</h4>
                  {/* <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-2.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="item">
                  <div className="flip-card">
                    <img src="./images/award-3.jpg" alt="Avatar" />
                    <h4>E-Governance Excellence 2014</h4>
                    {/* <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-3.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div class="item">
                  <div className="flip-card">
                    <img src="./images/award-4.jpg" alt="Avatar" />
                    <h4>mBillionth-2014</h4>
                    {/* <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-4.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div class="item"><div className="flip-card">
                  <img src="./images/award-5.jpg" alt="Avatar" />
                  <h4>Salaam Madhya Pradesh Award 2014</h4>
                  {/* <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-5.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div> */}
                </div></div>
                <div class="item">
                  <div className="flip-card">
                    <img src="./images/award-6.jpg" alt="Avatar" />
                    <h4>IMC IT Awards</h4>
                    {/* <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img src="./images/award-6.jpg" alt="Avatar" />
                      </div>
                      <div className="flip-card-back">
                        <p>Best Mobile App Digital India 2016</p>
                      </div>
                    </div> */}
                  </div></div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Owlslider
