export const Sliderdata =[
  {
  image: "./../../images/header-bg1.jpg",
  heading: [<p>A <span>simple curiosity</span> leading to a huge discovery, eventually led to this goal.</p>],
  desc: "Moving Forward With An Undying Passion Of Harnessing Technology For Social Improvement. ",
  link: "../../Socialprofile"
},
{
  image: "./../../images/slide-2.jpg",
  heading: [<p>He entered the world of technology with a simple curiosity.</p>],
  desc: "The Aim Has Been To Use Technology For Touching The Lives Of As Many People As Possible. ",
  link: "../../Socialprofile"
},
{
  image: "./../../images/slide-3.jpg",
  heading: [<p>His mind wondered what the advancing technology could do. </p>],
  desc: "New initiatives took birth, and are striving to tackle diverse social challenges.",
  link: "../../Socialprofile" 
}
]
