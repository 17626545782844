const initiativeapi = [
  {
    id: 1,
    image: './images/icon1.png',
    title: "CitizenCOP",
    Link: "http://www.citizencop.org/",
    info:"A Digital India award-winning application, built on safety, empowerment, and convenience recommended by BPR&D, MHA, GOI to all state government, DGPs and IGs.",
  },
  {
    id: 2,
    image: './images/icon7.png',
    title: "CyberCOP ", 
    Link: "https://play.google.com/store/apps/details?id=com.info.cybercop&gl=GB",
    info: "The go-to solution for fighting cybercrime, empowering users to protect themselves online, and boosting awareness, all in one user-friendly platform.",
  },
  {
    id: 3,
    image: './images/icon2.png',
    title: "greenGENE", 
    Link: "http://greengene.citizencop.org/",
    info: "A green revolution initiative executing plantation with public participation, giving QR code identity to trees, record keeping, and improving their survival rate.",
  },
  {
    id: 4,
    image: './images/icon3.png',
    logo: "fas fa-money-check-alt",
    title: "Aahar",
    Link: "http://www.aahar.org.in/",
    info: "An innovative mobile app that tackles food wastage by spreading awareness, and connecting donors with those in need, ensuring surplus food reaches hungry individuals.",
  },
  {
    id: 5,
    image: './images/icon8.png',
    logo: "fas fa-money-check-alt",
    title: "Smart Family",
    Link: "https://play.google.com/store/apps/details?id=com.info.smartfamily",
    info: "The app offers information related to health, financial literacy, estate planning, and cyber hygiene making families smarter. It provides sample templates that users can fill with their own data regarding documents, wills, and more for their use.",
  },
  {
    id: 6,
    image: './images/icon9.png',
    logo: "fas fa-money-check-alt",
    title: "Blood Buddy",
    Link: "https://play.google.com/store/apps/details?id=com.info.bloodbank",
    info: " Our blood donation app combines compassion and convenience, rewarding your contributions with credits for future transfusions. Join us in sharing life, one drop at a time.",
  },
  {
    id: 7,
    image: './images/icon10.png',
    logo: "fas fa-money-check-alt",
    title: "Ankush",
    Link: "#",
    info: " Indore police's essential tool. Crime dossier, jail records, preventive actions, and more. Simplifies daily police tasks, empowering officers with comprehensive criminal information. Streamlining Indore's law enforcement efforts for a safer community.",
  },
  {
    id: 8,
    image: './images/know_your_cop.png',
    logo: "fas fa-money-check-alt",
    title: "Know Your COP",
    Link: "https://play.google.com/store/apps/details?id=com.info.traffic",
    info: "Scan QR codes at nearby police stations to instantly access details of duty officers. Get crucial info like Name, Mobile Number, and more right on your device. Used for accountable police patrolling.  ",
  },
  {
    id: 9,
    image: './images/citizen_eye.png',
    logo: "fas fa-money-check-alt",
    title: "Citizen EYE ",
    Link: "https://play.google.com/store/apps/details?id=com.info.citizeneyeapp",
    info: "This initiative enables users to report crimes by sharing access to their CCTV cameras. It provides the police with valuable data and saves government expenses on security camera installations. ",
  },
  {
    id: 10,
    image: './images/icon11.png',
    logo: "fas fa-money-check-alt",
    title: "eProCOP",
    Link: "https://play.google.com/store/apps/details?id=com.info.eprocop.notebook",
    info: "A tool designed for the internal use by the police department for proficient, professional and proactive policing. It also brings a level of transparency to the system, giving senior officials better insights.",
  },
  {
    id: 11,
    image: './images/icon4.png',
    logo: "fab fa-amazon-pay",
    title: "Smart Fertilizer",
    Link: "https://play.google.com/store/apps/details?id=com.info.fertilizershajapur",
    info: "Created mainly for the convenience of the farmers. In this application farmers will get the demand and distribution of fertilizers required for crop production through the app.  Farmers and committees will also benefit from the demand and distribution of fertilizers of their choice through the app. ",
  },
  {
    id: 12,
    image: './images/icon6.png',
    logo: "fas fa-globe",
    title: "Vidhya Daan",
    Link: "http://www.vidhyadaan.com/",
    info: "An initiative for professionals to contribute towards development of an educated society, in partnership with government educational departments and civil society organizations. The app allows volunteers to register and select school, class and subject they wish to teach in government school. Students, housewives, working professionals and others can register. ",
  },
  {
    id: 13,
    image: './images/icon12.png',
    logo: "fas fa-money-check-alt",
    title: "Juvenile Justice",
    Link: "#",
    info: " A campaign solving problems like child abuse, child trafficking, child labour and depression and importantly delay in justice. It's a tool developed to create a consolidated platform for all stakeholders involved in the process to streamline the process and offers proper care and justice to juveniles. ",
  },
  {
    id: 14,
    image: './images/icon5.png',
    logo: "fas fa-hand-holding-usd",
    title: "Jal Mitra",
    Link: "https://play.google.com/store/apps/details?id=com.info.jalmitra",
    info: " The app aims to serve as authentic base for information on the city’s lakes . Its an initiative by Divisional Commissioner Indore Shri Akash Tripathi and runs under a registered society in the name of Jheel aiwam Bhu Jal Sanrakshan Society, Indore. ",
  }
];

export default initiativeapi;
