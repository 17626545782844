import React from 'react';
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { HashLink } from 'react-router-hash-link';
const Videogallery = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      {/* <section className='home-video'> */}
      <section className='home-video'>
        <div className='container'>
          <div className='row m-0'>
            <div className='col-9'><h3 className="fw-bold">VIDEO GALLERY </h3></div>
            <div className='col-3 text-right'>
              {/* <NavLink className="text-blue" to="/videos">View All</NavLink> */}
              <HashLink smooth to='/videos' target='_blank'> View All  <i className='fa fa-long-arrow-right'></i></HashLink>
            </div>
          </div>
 
          <div className='row m-0'>
          <div className='col-sm-4 col-12' >
              <iframe title="Rakesh Jain | Indore Talk | MP 09 Special | Special Interview" width="100%" height="300" src="https://www.youtube.com/embed/5YIgIEoJm0U?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
            <div className='col-sm-4 col-12' >
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/IkMchO1a8P4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='col-sm-4 col-12' >
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/NFmj1UhBscg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className='text-center mt-4'>
          {/* <HashLink smooth to='/videos' target='_blank'> View All </HashLink>
            <NavLink to="/videos" className='btn btn-blue'>View More </NavLink> */}
            </div>
           </div>
      </section>
    </>
  )
}

export default Videogallery
