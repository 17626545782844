const blogapi = [ 
    {
        id: 1,
        image: './images/blog-1.png',
        title: "Has Social Media Changed You In Covid-19?",
        Link: "../covid19",
       //Link: "https://rakesh-jain.com/2022/06/13/has-social-media-changed-you-in-covid-19/",
        info: "A million debates about social media, and here's another one, you may be thinking. However,",
    },
    {
        id: 2,
        image: './images/blog-2.png',
        title: "Don't Forget to Pack Passion & Persistence If You are Traveling up to Success!",
        Link: "../packpassionpersistence",
        info: "You must have heard how passion and persistence are the keys to living a successful",
    },
    {
        id: 3,
        image: './images/blog-3.png',
        title: "How organic living became a turning point in my life!",
        Link: "../OrganicLiving",
        info: "The pandemic brought many things with it. We learnt how to live with masks; we",
    }

];
export default blogapi;