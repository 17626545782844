import React, { useState } from 'react';
import Footer from '../Footer';
import Navbar from '../navbar';
import initiativeapi from '../API/initiativeApi';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';
const Portfolio = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const [initiativeData, setinitiativeData] = useState(initiativeapi);
  return (
    <>
          <Helmet>
          <title>Portfolio</title>
      <meta name="author" content="Portfolio" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
    <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
    <Navbar />
    <div className='inner-banner'>
    <img src='./images/portfolionew.jpg' />
      {/* <div className='container'>
        
          <div className='left'><h1>Portfolio 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      </div>
      <div className='clearfix'></div>
      <section className='inner-content'>
      <div className='container'>
      <div className="intiative-main-container-inner p-0">
        <div className="container">
          <h5 className='mb5'>Mr. Rakesh Jain is Working Across Diverse Sectors That Need Attention</h5>
          <h3 className="fw-bold">
          INITIATIVES BY MR. RAKESH JAIN
          </h3>
          <div className='row mt6'>
            {initiativeData.map((curElem) => {
              const { Link, id, title, image, info } = curElem;
              return (
                <>
                <div className=' col-sm-4'>
                <div key={id} className="inner-work-div">
                <a href={Link}  target={'_blank'}>
                <div className='row text-center'>
                <div className='col-sm-12'><img src={image} alt="test" className="ini-img" /></div>
                <div className='col-sm-12'><h5 className="sub-heading">{title}</h5>
                    <p className="main-hero-para">{info}</p></div>
                </div>
                </a>
                </div></div>
                  {/* <div
                    className="col-11 col-lg-4 col-xxl-4"
                    key={id}>
                    <div className='work-container-subdiv'>
                    <a href={Link}  target={'_blank'}> <img src={image} alt="test" className="ini-img" /></a>
                    <h5 className="sub-heading">{title}</h5>
                    <p className="main-hero-para">{info}</p>
                  </div></div> */}
                </>
              );
            })}
          </div>
        </div>
      </div>
      </div>
      </section>
      
      <Footer />
    </>
 
  )
}

export default Portfolio
