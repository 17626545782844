const allblogapi = [
    {
        id: 1,
        image: './images/blog-1.png',
        title: "Has Social Media Changed You In Covid-19?",
        Link: "../covid19",
       //Link: "https://rakesh-jain.com/2022/06/13/has-social-media-changed-you-in-covid-19/",
        info: "A million debates about social media, and here's another one, you may be thinking. However,",
    },
    {
        id: 2,
        image: './images/blog-2.png',
        title: "Don't Forget to Pack Passion & Persistence If You are Traveling up to Success!",
        Link: "../packpassionpersistence",
        info: "You must have heard how passion and persistence are the keys to living a successful",
    },
    {
        id: 3,
        image: './images/blog-3.png',
        title: "How organic living became a turning point in my life!",
        Link: "../organicliving",
        info: "The pandemic brought many things with it. We learnt how to live with masks; we",
    },
    {
        id: 4,
        image: './images/blog-4.png',
        title: "The positives a pandemic brought to our lives and to the mother nature.",
        Link: "../Positivesapandemicbrought",
        info: "Every single morning, we wake up and read about the increasing positive Corona cases around",
    },
    {
        id: 5,
        image: './images/blog-5.jpg',
        title: "Alumni can be the pumping heart of an Institution!",
        Link: "../HeartofanInstitution",
        info: 'Often questions like “What is the best institute for me to study at"? or Which',
    },
    {
        id: 6,
        image: './images/blog-6.jpg',
        title: "How carbon credits changing green wealth!!",
        Link: "../Howcarboncreditschanginggreenwealth",
        info: "Carbon credit is a generic term for any certificate that is tradable and represents the right to emit one tone of carbon dioxide or the equivalent amount of a different greenhouse gas.",
    },
    {
        id: 7,
        image: './images/blog-7.jpg',
        title: "What's our future, if we don’t plan it together?",
        Link: "../Whatsourfuture",
        info: "In the age where humans are unkind to each other and are the only species",
    },
    {
        id: 7,
        image: './images/deepfake.png',
        title: "Beware of Deep Fakes: A Cyber Security Pro's Take",
        Link: "../Deepfake",
        info: "As someone who's all about keeping things safe online, I want to chat with you about deepfakes. ",
    },

];
export default allblogapi; 