import React, { useState } from 'react';
import blogapi from './API/blogApi';
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { HashLink } from 'react-router-hash-link';

const Blog = () => {
  const [blogData, setBlogData] = useState(blogapi);
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
   {/* <section className="blog">
        <div className="container"> */}
    <section className='blog'>
        <div className="container">     
          <div className='row m-0'>
      <div className='col-6'><h3 className="fw-bold">LATEST BLOG</h3></div>
      <div className='col-6 text-right'>
      <HashLink smooth to='/allblog' target='_blank'> View All <i className='fa fa-long-arrow-right'></i></HashLink>
            {/* <NavLink to="/allblog" className='text-blue'>Read More <i className='fa fa-long-arrow-right'></i> </NavLink> */}
      </div>
      </div>
          <div className="row">
          {blogData.map((curElem) => {
                            const { Link, id, title, image, info } = curElem;
                            return (<>
                                <div class="col-sm-4" key={id}>
                                    <div class="card-info text-center">
                                        <div class="card-wrapper">
                                            <div class="card-image">
                                            <img src={image} alt="Blog" />
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <h5>{title}</h5>
                                            <div class="description">
                                                    {info}
                                            </div>
                                            <div class="card-btn-wrapper">
                                                <a href={Link} class="btn btn-border" target={'_blank'}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                        }) }
          </div>
        </div>
      </section>
     
    </>
  )
}





/*const Blog = () => {
    const [blogData, setBlogData] = useState([blogapiApp]);
   
    return (
        
        <>
            <section className='blog'>
                <div class="container">
                    <div class="row">
                    {blogData.map((curElem) => {
                            const { Link, id, title, image, info } = curElem;
                            return (<>
                                <div class="col-sm-4" key={id}>
                                    <div class="card-info text-center">
                                        <div class="card-wrapper">
                                            <div class="card-image"> <img src={image} alt="test" /></div>
                                        </div>
                                        <div class="card-content">
                                            <h5>{title}</h5>
                                            <div class="description"> {info} </div>
                                            <div class="card-btn-wrapper">
                                                <a href="{Link}" class="btn btn-border">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                        }) }


                        
                    </div>
                </div>
            </section>
        </>
    )
}*/

export default Blog
