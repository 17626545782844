import React, { useState } from "react";
import allblogapi from "../API/allblogApi";
import Footer from "../Footer";
import Navbar from "../navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LinkedInEmbed } from "react-social-media-embed";

const Allblog = () => {
  useEffect(() => {
    AOS.init();
    // Function to hide elements with the 'tutorial_link' class
    const hideTutorialLinks = () => {
        const elements = document.querySelectorAll('.tutorial_link');
        elements.forEach(element => {
          element.style.display = 'none';
        });
      };
  
      // Initial check in case elements are already present
      hideTutorialLinks();
  
      // Setup MutationObserver to watch for changes in the DOM
      const observer = new MutationObserver(hideTutorialLinks);
  
      // Observe the entire document for added nodes
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });


       // Create the script element
        const script = document.createElement('script');
        script.src = 'https://widgets.sociablekit.com/linkedin-profile-posts/widget.js';
        script.async = true;
        script.defer = true;

        // Append the script to the document body
        document.body.appendChild(script);
  
      // Cleanup the observer when the component unmounts
      return () => {
        observer.disconnect();
        document.body.removeChild(script);
      };
  }, []);
  const [allblogData, setAllBlogData] = useState(allblogapi);
  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name="author" content="Blog" />
        <meta name="revisit-after" content="7 days" />
        <meta name="robots" content="index,follow" />
        <meta name="rating" content="general" />
        <meta name="site" content="Rakesh-jain" />
        <meta name="geo.region" content="India" />
        <meta name="geo.placename" content="Indore" />
        <meta name="YahooSeeker" content="index,follow" />
        <meta name="msnbot" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="googlerank" content="all" />
        <meta name="editors-url" content="http://www.rakesh-jain.com" />
        <meta name="Distribution" content="Global" />
        <meta name="email" content="rakesh@infocratsweb.com" />
        <meta
          name="description"
          content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people."
        />
        <link rel="canonical" href="https://rakesh-jain.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR"
        />
        <meta
          property="og:description"
          content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people."
        />
        <meta property="og:url" content="https://rakesh-jain.com/" />
        <meta property="og:site_name" content="Rakesh Jain" />
        <meta
          property="article:modified_time"
          content="2022-08-02T09:54:51+00:00"
        />
        <meta name="author" content="admin" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Navbar />
      <div className="inner-banner">
        <img src="./images/blognew.jpg" />
        {/* <div className='container'>
        
          <div className='left'><h1>Blog 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      </div>

      <div className="clearfix"></div>
      <section className="blog mt1">
        <div className="container">
          {/* <div className="row">
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-1.png" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>Has Social Media Changed You In Covid-19?</h5>
                  <div class="description">
                    A million debates about social media, and here's another one, you may be thinking. However,
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/covid19" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-2.png" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>Don't Forget to Pack Passion & Persistence If You are Traveling up to Success!</h5>
                  <div class="description">
                  You must have heard how passion and persistence are the keys to living a successful
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/packpassionpersistence" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-3.png" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>How organic living became a turning point in my life!</h5>
                  <div class="description">
                  The pandemic brought many things with it. We learnt how to live with masks
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/heartofanInstitution" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-4.png" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>The positives a pandemic brought to our lives and to the mother nature.</h5>
                  <div class="description">
                  Every single morning, we wake up and read about the increasing positive Corona cases around
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/Positivesapandemicbrought" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-5.jpg" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>Alumni can be the pumping heart of an Institution!</h5>
                  <div class="description">
                  Often questions like “What is the best institute for me to study at"? or Which
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/HeartofanInstitution" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-6.jpg" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>How carbon credits changing green wealth!!</h5>
                  <div class="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/Howcarboncreditschanginggreenwealth" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-sm-4">
              <div class="card-info text-center">
                <div class="card-wrapper">
                  <div class="card-image">
                    <img src="../images/blog-7.jpg" alt="test" />
                  </div>
                </div>
                <div class="card-content">
                  <h5>What's our future, if we don't plan it together?</h5>
                  <div class="description">
                  In the age where humans are unkind to each other and are the only species
                  </div>
                  <div class="card-btn-wrapper">
                    <NavLink to="/Whatsourfuture" className='btn btn-border' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            </div> */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <LinkedInEmbed
              url="https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384"
              postUrl="https://www.linkedin.com/posts/peterdiamandis_5-discoveries-the-james-webb-telescope-will-activity-6898694773406875648-z-D7"
              width={325}
              height={570}
            />
          </div> */}
          {/* elfsight Linkedin Post Widget  */}
          {/* <div class="elfsight-app-de489a16-7adf-44af-abcc-79b52df46689" data-elfsight-app-lazy></div> */}
          {/* sociablekit Linkedin Post Widget  */}
          
          <div className="row">
          <div class='sk-ww-linkedin-profile-post' data-embed-id='25455714'></div>
            {/* {allblogData.map((curElem) => {
              const { Link, id, title, image, info } = curElem;
              return (
                <>
                  <div class="col-sm-4" key={id}>
                    <div class="card-info text-center">
                      <div class="card-wrapper">
                        <div class="card-image">
                          <img src={image} alt="test" />
                        </div>
                      </div>
                      <div class="card-content">
                        <h5>{title}</h5>
                        <div class="description">{info}</div>
                        <div class="card-btn-wrapper">
                          <a
                            href={Link}
                            class="btn btn-border"
                            target={"_blank"}
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })} */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Allblog;
