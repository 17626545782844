import React from 'react';
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

const Calltoaction = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      {/* <section className='clltoaction'> */}
      <section className='clltoaction' >
        <div className='container'>
            <div className='row'>
                <div className='col-sm-8'>
                    <h3>
                    DIG DEEPER<br />
INTO THE SOCIAL<br />
<span>INITIATIVES</span></h3>
<p>DIVERSE CONTRIBUTIONS TO THE SOCIETY</p>
                   
                </div>
                <div className='col-sm-4'>
                  <a href='./images/Coffee-Table-Book-Doc.pdf' className='btn btn-red-border btn-big' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i></a>
                {/* <a href='https://www.infocratsweb.com/social/social-contributions.pdf' className='btn btn-red-border btn-big' target={'_blank'}>Read More <i className='fa fa-long-arrow-right'></i></a> */}
                
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Calltoaction
