import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const Navbar = () => {
const [show, setShow] = useState(false);
const [navbar, setNavbar] = useState(false);
const changeBackground = () =>{
  if (window.scrollY >= 80){
    setNavbar(true);
  }else{
    setNavbar(false)
  }
};
window.addEventListener('scroll', changeBackground )

  
  return (
    <>
      <section className="navbar-bg">
        <nav className={navbar  ? 'navbar active navbar-expand-lg' : 'navbar navbar-expand-lg'}>
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              <span>RAKESH</span> JAIN
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
        <NavLink className="nav-link" to="/" isActive={(match, location) => {
    if (!match) { return false; }}}>Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/about">About</NavLink>
      </li>
      {/* <li className="nav-item" onClick={() => scrollToSection(portfolio)}>
        Portfolio
      </li> 
      <li className="nav-item">
      <NavLink className="nav-link" to="/portfolio">Portfolio</NavLink>
        
      </li>*/}
      <li className="nav-item">
        <NavLink className="nav-link" to="/milestone">Milestones</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/memorygallery">Memories</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/videos">Video</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/allblog">Blog</NavLink>
      </li>
      <li className="nav-item">
         <NavLink className="nav-link" to="/contact">Contact</NavLink> 
      </li>
      {/*<li className="nav-item"  onClick={() => scrollToSection(contact)}>
      Contact
         <NavLink className="nav-link" to="/contact">Contacts</NavLink>
      </li> */}
              </ul>
              
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Navbar;
