const awardapi = [
    {
        id: 1,
        image1: './images/cybercop.jpg',
        title: "Launch of CyberCOP App at Indore",
        info: "Presented by -  Commissioner of Police (CP) Mr. Makrand Deouskar,  Additional CP (Crime/Headquarters) Mr. Manoj Kumar Shrivastava, Additional CP (Law and Order) Mr. Manish Kapooria, and DCPs (06-Dec-2023)",
    },
    {
        id: 2,
        image1: './images/imc-award1.jpg',
        image2: './images/imc-award2.jpg',
        image3: './images/imc-award3.jpg',
        title: "IMC IT Awards",
        info: "Our application was declared a unanimous winner as best emerging technology at IMC-IT awards 2013. IMC is a renowned national level committee established since 1907 and has been conducting such awards since a decade, to appreciate innovative work done in the field of technology and business. CitizenCOP was honored with victory by Mr. P.S. Deodhar: Chairman of APLAB and Former Chairman of Electronic Commission.",
    }, 
    {
        id: 3,
        image1: './images/mBillionth-2014-2.jpg',
        image2: './images/mBillionth-2014-1.jpg',
        title: "mBillionth-2014",
        info: "CitizenCOP has been recognized for its excellence in empowering women and children at mbillionth South Asia Innovative Mobile Technology Awards 2014. The mBillionth Award was launched in the year 2010 to recognize & honor excellence in mobile communications across South Asia. It provides a dynamic forum and ecosystem for the mobile and telecom stakeholders in South Asia to experience, share and exchange ideas, innovations and partnerships, all towards meeting governance, development and citizen services needs for more than 1.5 billion population in the region.",
    },
    {
        id: 4,
        image1: './images/e-Governance-Excellence-slide4.jpg',
        image2: './images/e-Governance-Certificate-1.jpg',
        image3: './images/e-Governance-Excellence-slide3.jpg',
        title: "e-Governance Excellence Award 2014",
        info: "CitizenCOP was awarded the e-Governance Excellence Award on 13th April 2015. This prestigious award was conferred on Mr. Rakesh Jain, CEO, Infocrats Web Solutions. Shivraj Singh Chouhan, the current Chief Minister of Madhya Pradesh, presided over the ceremony and presented the award. E-Governance Excellence Award recognizes and promotes the implementation of technology for e-Governance.",
    },
    {
        id: 5,
        image1: './images/citizincop-launch.jpg',
        title: "The Grand Launch of CitizenCOP",
        info: "Citizen COP, one of our finest mobile application creations, was launched on a grand scale in multiple cities like Bhopal, Indore, and Chindwara. The launch ceremony was honored with the presence of top dignities of Madhya Pradesh – former home minister of M.P. Mr. Umashankar Gupta, IAS Collector Indore Mr. Akash Tripathi, IG. Indore Mr. Vipin Maheshwari and much more. ",
    },
    {
        id: 6,
        image1: './images/GRPHelpApp.jpg',
        title: "Recognition for Efforts towards GRP Help App",
        info: "Government Railway Police presented Mr. Rakesh Jain with a Certificate rewarding his contribution towards GRP Help App. Developed with the aim of empowering passengers of Indian Railway, GRP Help app is the official app of Government Railway Police. This app was successfully made by Infocrats Web Solutions.",
    },
    
]
export default awardapi;