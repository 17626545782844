import React from 'react'
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import '../node_modules/popper.js/dist/popper.min.js';
import Scrolltotop from './components/scrolltotop';
//import { Helmet } from 'react-helmet';

 const root = ReactDOM.createRoot(document.getElementById('root'));
 root.render(
  <>
   <BrowserRouter>
   <React.StrictMode>
     <App />
     <Scrolltotop />
   </React.StrictMode>
   </BrowserRouter>,

   </>
 );