import React from 'react';
import Footer from '../Footer';
import Navbar from '../navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';
const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
          <Helmet>
          <title>Contact</title>
      <meta name="author" content="Contact Us" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
    <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
    <Navbar />
    <div className='inner-banner'>
    <img src='./images/contactnew.jpg' />
      {/* <div className='container'>
        
          <div className='left'><h1>Contact 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      </div>
      <div className='clearfix'></div>
      <section className='contact-desc'>
      <div className='container '>
        <div className='row align-items-center'>
          <div className='col-sm-6'>
            <div className='content'>
          <h4>
      Let's connect and explore the endless possibilities of how we can together make this world a better place by using technology as our wand.
      <br />
     <span>I'm eagerly awaiting to tap into this opportunity with you.</span> 
      </h4>
          </div>
          </div>
          <div className='col-sm-5 offset-sm-1 icons'>
            <table width="100%">
              <tr>
                <td><img src='./images/contact2.png' width="60"  /></td>
                <td>+91-9826076770</td>
              </tr>
              <tr>
                <td><img src='./images/contact1.png' width="60"  /></td>
                <td>rakesh@infocratsweb.com</td>
              </tr>
              <tr>
                <td></td>
                <td> <ul className='social-icon'>
                      <li><a href='https://www.facebook.com/rakesh.jain.7161953' target={'_blank'}><i className='fa fa-facebook'></i></a></li>
                      <li><a href='https://twitter.com/Rakesh_CCOP' target={'_blank'}><i className='fa fa-twitter'></i></a></li>
                      <li><a href='https://www.instagram.com/rakeshjain_17/' target={'_blank'}><i className='fa fa-instagram'></i></a></li>
                      <li><a href='https://www.linkedin.com/in/rakeshjain17' target={'_blank'}><i className='fa fa-linkedin'></i></a></li>
                      <li><a href='https://www.youtube.com/channel/UCclfOAGbc31eCtX1aRfFu8A/' target={'_blank'}><i className='fa fa-youtube'></i></a></li>
                    </ul></td>
              </tr>
            </table>
           
          </div>
        </div>
     
      {/* <div className='row'>
      <div className='col-sm-6'>dsfs</div>
      <div className='col-sm-6'><img src="./images/main-img.jpg" /></div>
      </div> */}
      </div>
      </section>
      
      <Footer />
    </>
  )
}

export default Contact
