import React from 'react';
import Header from './header';
import Navbar from "./navbar";
import About from './about';
import Initiatives from './initiatives';
import Owlslider from './Owlslider';
import Gallery from './Gallery';
import Videogallery from './Videogallery';
import Blog from './Blog';
import Calltoaction from './Calltoaction';
import Footer from './Footer';
import Slider from './components/slider/slider';
import { Helmet } from 'react-helmet';
//import { Helmet } from 'react-use';
const Home = () => {
  return (
   
    <>
      <Navbar />
      <Helmet>
      <title>RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR</title>
      <meta name="author" content="Rakesh Jain"/> 
<meta name="description" itemProp="description" content="Rakesh Jain Social Entrepreneur & Philanthropist Entered IT World With A Simple Curiosity To Use Technology For Touching The Lives Of As Many People As Possible."/>
<meta name="revisit-after" content="7 days" /> 
<meta name="robots" content="index,follow" /> 
<meta name="rating" content="general" /> 
<meta name="site" content="Rakesh-jain" />
<meta name="geo.region" content="India" />
<meta name="geo.placename" content="Indore" />
<meta name="YahooSeeker" content="index,follow" /> 
<meta name="msnbot" content="index,follow" /> 
<meta name="googlebot" content="index,follow" /> 
<meta name="googlerank" content="all" /> 
<meta name="editors-url" content="http://www.rakesh-jain.com" />
<meta name="Distribution" content="Global" />
<meta name="email" content="rakesh@infocratsweb.com" />
<meta name="Rating" content="General" />
   </Helmet>
   {/* <Navbar /> */}
      <Slider />
      {/* <Header /> */}
      <About />
      <Initiatives />
      <Owlslider />
      <Calltoaction />
      <Gallery />
      <Videogallery />
      <Blog />
      <Footer />
       
    </>
  )
}

export default Home
