import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';

const Scrolltotop = () => { 
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);
    useEffect(() =>{
        if(pageYOffset > 400){
            setVisiblity(false);
        }else{
            setVisiblity(true);
        }
    }, [pageYOffset]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior:"smooth" })

    if(visible){
        return false;
    }
  return (
    <>
      <div className='scroll-to-top' onClick={scrollToTop}>
        <i className='icon fa fa-chevron-up'></i>
      </div>
    </>
  )
}

export default Scrolltotop
