import React from 'react';
import Footer from '../Footer';
import Navbar from '../navbar';
import Memories from './Memoriespage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import { Helmet } from 'react-helmet';
const Memorygallery = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
    <Helmet>
          <title>Memories</title>
          <meta name="author" content="Contact Us" />
	  <meta name="revisit-after" content="7 days" />
	  <meta name="robots" content="index,follow" />
	  <meta name="rating" content="general" />
	  <meta name="site" content="Rakesh-jain" />
	  <meta name="geo.region" content="India" />
	  <meta name="geo.placename" content="Indore" />
	  <meta name="YahooSeeker" content="index,follow" />
	  <meta name="msnbot" content="index,follow" />
	  <meta name="googlebot" content="index,follow" />
	  <meta name="googlerank" content="all" />
	  <meta name="editors-url" content="http://www.rakesh-jain.com" />
	  <meta name="Distribution" content="Global" />
	  <meta name="email" content="rakesh@infocratsweb.com" />
          <meta name="description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<link rel="canonical" href="https://rakesh-jain.com/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="RAKESH JAIN | RAKESH JAIN INDORE - SOCIAL ENTREPRENEUR" />
	<meta property="og:description" content="Rakesh Jain from Indore has 25 years of entrepreneur experience in the field of IT and social work. Rakesh Jain initiatives are changing the lives of so many people." />
	<meta property="og:url" content="https://rakesh-jain.com/" />
	<meta property="og:site_name" content="Rakesh Jain" />
	<meta property="article:modified_time" content="2022-08-02T09:54:51+00:00" />
	<meta name="author" content="admin" />
	<meta name="twitter:card" content="summary_large_image" />
   </Helmet>
      <Navbar />
      <div className='inner-banner'>
      <img src='./images/memoriesnew.jpg' />
      
      {/* <div className='container'>
        
          <div className='left'><h1>Memories 
<span>Mr. Rakesh Jain</span></h1>
</div>
          <div className='right'><h2><span>He Entered The</span>
World of Technology<br/> With A Simple Cutiosity</h2>

        </div>
      </div> */}
      </div>
      <div className='clearfix'></div>
      <section className='inner-content'>
        <div className='container '>
            <Memories />
        </div>
        </section>
        <Footer />
    </>
  )
}

export default Memorygallery
